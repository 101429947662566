import {combineReducers} from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Employee from "./Employee";
import {connectRouter} from 'connected-react-router'
import Company from "./Company";

export default (history) => combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    company: Company,
    employee: Employee,
});
