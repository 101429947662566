import {EMPLOYEE_RELATIONSHIPS} from "../../constants/ActionTypes";

const INIT_STATE = {
    relationships: [],

};


export default (state = INIT_STATE, action) => {
    switch (action.type) {


        case EMPLOYEE_RELATIONSHIPS: {
            return {
                ...state,
                relationships: action.payload,
            }
        }


        default:
            return state;
    }
}