import {ACTIVE_COMPANY, SAVE_COUNTRIES} from "../../constants/ActionTypes";

const INIT_STATE = {
    companyDrawer: false,
    activeCompany: null,
    countries:[],

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {


        case ACTIVE_COMPANY: {
            return {
                ...state,
                activeCompany: action.payload,
            }
        }
        case SAVE_COUNTRIES: {
            return {
                ...state,
                countries: action.payload,
            }
        }

        default:
            return state;
    }
}