import React, {useEffect} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";


import UserProfile from "./UserProfile";

import {useSelector} from "react-redux";
import {HomeFilled,} from "@ant-design/icons";
import {

    FaBriefcase,
    FaUser,
    FaBook
} from "react-icons/fa";
import Helpers from "../../util/Helpers";


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {
    const {authorizations} = useSelector(({auth}) => auth);
    let {navStyle, themeType} = useSelector(({settings}) => settings);
    let {pathname} = useSelector(({common}) => common);
    let {authUser} = useSelector(({auth}) => auth);


    let selectedKeys = pathname.substr(1);
    let defaultOpenKeys = selectedKeys.split('/')[1];
    // const defaultOpenKeys = selectedKeys.split('/')[1];

    return (
        <>


            <div className="flex items-center justify-center py-2 px-2">
                <UserProfile/>
                {/*<AppsNavigation/>*/}
            </div>


            <Menu
                className="bg-blue text-white text-center text-base font-medium my-5"
                mode="inline"
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}>

                <MenuItemGroup key="dashboard" className="mb-5">
                    {Helpers.authorizeMe(authorizations[9], 'view', 47) && (
                        <Menu.Item key="home">
                            <Link to="/home" className="flex">
                                <div className="text-2xl">
                                    <HomeFilled/>
                                </div>
                                <span className="mx-4 py-1">Home</span>
                            </Link>
                        </Menu.Item>)}
                    {Helpers.authorizeMe(authorizations[9], 'view', 48) && (
                        <Menu.Item key="clients">
                            <Link to="/clients" className="flex">
                                <div className="text-2xl -ml-1 mt-3">
                                    <FaBriefcase/>
                                </div>
                                <span className="mx-5 py-1">Clients</span>
                            </Link>
                        </Menu.Item>)}

                    {Helpers.authorizeMe(authorizations[9], 'view', 49) && (
                        <Menu.Item key="employees">
                            <Link to="/employees" className="flex">
                                <div className="text-2xl -ml-1 mt-3">
                                    <FaUser/>
                                </div>
                                <span className="mx-5 py-1">Employees</span>
                            </Link>
                        </Menu.Item>)}

                    {Helpers.authorizeMe(authorizations[9], 'view', 50) && (
                        <Menu.Item key="payroll">
                            <Link to="/payroll" className="flex">
                                <div className="text-2xl -ml-1 mt-3">
                                    <FaBook/>
                                </div>
                                <span className="mx-5 py-1">Payroll</span>
                            </Link>
                        </Menu.Item>)}

                </MenuItemGroup>


            </Menu>
        </>
    );
};

SidebarContent.propTypes = {};
export default SidebarContent;

