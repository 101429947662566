import moment from "moment";
import React from "react";

class Helpers {
    static objectFormBuilder(form, object) {


        for (let name in object) {

            if (object.hasOwnProperty(name)) {
                if (object[name] === undefined || object[name] === null || object[name] === "null") {

                    form.append(name, '');
                } else {

                    form.append(name, object[name]);
                }
            }
        }

        return form;
    }

    getDeviceName() {
        let name = navigator.appCodeName;
        return name.replace(" ", "_").toLowerCase();
    }

    static normalizeJson(object) {
        let newObject = {};
        for (let name in object) {

            if (object.hasOwnProperty(name)) {
                newObject[name] = object[name] ? object[name].toString() : '';
            }
        }

        return newObject;

    }

    static getMomentDateFromSQL(date, moment) {
        if (date) {
            return moment(new Date(date));
        }
        return '';
    }

    static getAge(date, moment) {
        if (date) {
            return moment(new Date(date)).fromNow();
        }
        return '';
    }

    static ageFromNow(date, moment) {
        if (date) {

           return moment().diff(moment(new Date(date)), 'day');

        }

        return '';
    }



    static getSQLDateFromMoment(date) {
        let dateFormat = 'YYYY-MM-DD';
        if (date) {

            return date.format(dateFormat);
        }
        return '';
    }

    static displayDate(date) {
        let dateFormat = 'YYYY-MM-DD';
        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        if (date) {
            let dateTime = new Date(date);
            let day = dateTime.getDate();
            let month = months[dateTime.getMonth()];
            let year = dateTime.getFullYear();
            return day + " " + month + ", " + year;
        }
        return '';
    }

    static displayDayMonth(date) {

        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        if (date) {
            let dateTime = new Date(date);
            let day = dateTime.getDate();
            let month = months[dateTime.getMonth()];

            return day + " " + month;
        }
        return '';
    }

    static reDisplayDate(date) {
        let dateFormat = 'YYYY-MM-DD';
        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        if (date) {
            let dateTime = new Date(date);
            let day = dateTime.getDate();
            if (day < 10) {
                day = '0' + day;
            }
            let month = months[dateTime.getMonth()];
            let year = dateTime.getFullYear();
            return day + "-" + month + "-" + year;
        }
        return '';
    }

    static displayDateTime(date) {

        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        if (date) {
            let dateTime = new Date(date);

            return dateTime.getDate() + " " + months[dateTime.getMonth()] + " " + dateTime.getFullYear() + " " + new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate(), dateTime.getHours() - 1, dateTime.getMinutes(), dateTime.getSeconds()).toLocaleTimeString();

        }
        return '';
    }

    static download(filename, path) {
        let element = document.createElement('a');
        element.setAttribute('href', path);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    static downloadOrOpen(filename, path) {
        let element = document.createElement('a');
        element.setAttribute('href', path);
        element.setAttribute('target', '_blank');
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }



    /*------------------------------
 *
 * ------------------------------
 *The authorize me must
 *ensure that it
 *has the application, type
 * and menu
 *
  */

    static authorizeMe(auth, type, menu) {
        try {

            return auth[type].includes(menu);

        } catch (e) {

        }
        return false;

    }

    static prepareAddress(address) {
        if (address) {

            let addIndex = address.indexOf("[");
            if (addIndex >= 0) {
                let parsedAddress = JSON.parse(address)[0];
                address = parsedAddress.house_number + ", " + parsedAddress.street_name + " " + parsedAddress.location + " " + parsedAddress.city

            }
        }

        return address;

    }


}

export default Helpers;