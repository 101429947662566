import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "../util/asyncComponent";

const App = ({match}) => (
    <div className="">
        <Switch>
            <Route path={`${match.url}/`} component={asyncComponent(() => import('./Home'))}/>
            <Route path={`${match.url}home`} component={asyncComponent(() => import('./Home'))}/>
            <Route path={`${match.url}clients`} component={asyncComponent(() => import('./Clients'))}/>
            <Route path={`${match.url}employees`} component={asyncComponent(() => import('./Employees'))}/>
            <Route path={`${match.url}payroll`} component={asyncComponent(() => import('./CompanyPayroll'))}/>
            <Route path={`${match.url}payroll-register/:id`}
                   component={asyncComponent(() => import('./CompanyPayroll/PayrollRegister'))}/>
        </Switch>
    </div>
);

export default App;
