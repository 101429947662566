import {
    ACTIVE_COMPANY, SAVE_COUNTRIES,
} from "../../constants/ActionTypes";
import axios from "../../util/Api";
import {EMPLOYEE_COMPANY_URL} from "../../constants/ServerUrl";


export const getActiveCompany = (location = "/") => {
    // console.log();
    let token = JSON.parse(localStorage.getItem("token"));
    //  axios.defaults.headers.common['Accept'] = " application/json";
    if (token) {

        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    }

    return (dispatch) => {

        axios.get(EMPLOYEE_COMPANY_URL).then(({data}) => {
            if (data.success) {


                dispatch({type: ACTIVE_COMPANY, payload: data.data});

            }
        }).catch(function (error) {
            //  dispatch({type: FETCH_ERROR, payload: error.message});

            console.log("Error****:", error.message);
        });
    }
};




export const saveCountries = (data) => {
    return {
        type: SAVE_COUNTRIES,
        payload: data
    };
}


